import React, { useEffect } from "react";

import { Card, Col, Row } from "antd";

import PageTitle from "../../layout/components/content/page-title";

import getCookies from "../../utils/getCookies";

export default function Home() {

  useEffect(() => {
    const token = getCookies('token_u')
    if(token) {
      console.log('ok')
    } else {
      window.location.href = "/user/login"
    }
  }, [])
  return (
    <Row gutter={[32, 32]}>
      <PageTitle
        pageTitle="Home"
      />

      <Col span={24}>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Card className="hp-border-color-black-40 hp-border-color-dark-80">
              <h4>Let's get started 🚀</h4>

              <Row>
                <Col md={12} span={24}>
                  <p className="hp-p1-body">Outil interne du u express place hoche. <a className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-hover-text-color-primary-3 hp-hover-text-color-dark-0 hp-transition" href="#" target="_blank">Documentation</a></p>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
