import axios from "axios"

export default function InterceptorUser() {
    axios.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        if (error.response && error.response.status === 401) {
            window.location.href = '/user/login';
        }
      
        return Promise.reject(error);
      });
}