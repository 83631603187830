import { lazy } from "react";

const PagesRoutes = [
  // PAGES
  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/error-404",
    component: lazy(() => import("../../view/pages/errors/404")),
    layout: "FullLayout",
  },
  {
    path: "/pages/success/:id",
    component: lazy(() => import("../../view/pages/comparatif/success")),
    layout: "FullLayout"
  },
  {
    path: "/comparatif/liste",
    component: lazy(() => import("../../view/pages/comparatif/listeComparatif")),
    layout: "VerticalLayout"
  },
  {
    path: "/comparatif/add",
    component: lazy(() => import("../../view/pages/comparatif/addComparatif")),
    layout: "VerticalLayout"
  },
  {
    path: "/comparatif/edit/:id",
    component: lazy(() => import("../../view/pages/comparatif/edit")),
    layout: "VerticalLayout",
  },

  //CONCURENT
  {
    path: "/concurrent/liste",
    component: lazy(() => import("../../view/pages/concurrent/liste")),
    layout: "VerticalLayout",
  },

  // AUTH
  {
    path: "/user/login",
    component: lazy(() => import("../../view/pages/user/login/index")),
    layout: "FullLayout"
  },
  /*{
    path: "/user/register",
    component: lazy(() => import("../../view/pages/user/register/index")),
    layout: "FullLayout"
  },*/
  {
    path: "/user/recover-password",
    component: lazy(() => import("../../view/pages/user/recover-password/index")),
    layout: "FullLayout"
  },
  {
    path: "/user/reset-password",
    component: lazy(() => import("../../view/pages/user/reset-password/index")),
    layout: "FullLayout"
  }
];

export default PagesRoutes;