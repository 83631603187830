import { Award } from 'iconsax-react';
import { RiStore2Line } from "react-icons/ri";
import { RiSettings3Line } from "react-icons/ri";
import { RiShoppingBasketLine } from "react-icons/ri";
import { RiShoppingBasket2Line } from "react-icons/ri";

import IntlMessages from "../../layout/components/lang/IntlMessages";

const pages = [
    {
        header: <IntlMessages id="sidebar-pages" />,
    },
    {
        id: "errors",
        title: <IntlMessages id="sidebar-pages-comparative" />,
        icon: <i className="ri-price-tag-3-line" />,
        children: [
            {
                id: "liste",
                title: "Liste",
                navLink: "/comparatif/liste",
            },
            {
                id: "ajouter",
                title: "Créer un comparatif",
                navLink: "/comparatif/add",
            },
        ],
    },
    {
        id: "concurrent",
        title: <IntlMessages id="sidebar-pages-concurrent" />,
        icon: <RiStore2Line />,
        children: [
            {
                id: "conurrent_list",
                title: "Concurrent",
                navLink: "/concurrent/liste",
            }
        ],
    },
    {
        id: "products",
        title: <IntlMessages id="sidebar-pages-products" />,
        icon: <RiShoppingBasket2Line />,
        children: [
            {
                id: "products_list",
                title: "Liste",
                navLink: "/product/liste",
            }
        ],
    },
];

export default pages